import styled, { css } from 'styled-components'

interface LiveBannerProps {
  placement?: string
}

const getPlacement = (placement?: string) => {
  switch (placement) {
    case 'rail':
      return css`
        position: absolute;
        bottom: 16px;
        left: 16px;
      `
    case 'detailPanel':
      return css``
    default:
      return css`
        position: relative;
        bottom: 24px;
      `
  }
}
const LiveBannerWrapper = styled.div<LiveBannerProps>`
  display: flex;
  flex-direction: row;
  ${({ placement }) => getPlacement(placement)}
  background-color: var(--overlay-over-image);
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  max-width: 109px;
`

const RedDot = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--live);
  padding-right: 8px;
`

const LiveIndicator = styled.div`
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-left: 8px;
  /* This is using named font-styles because it's a psuedo element, in general don't do this */
  ::before {
    content: 'LIVE';
    display: block;
    color: var(--on-background);
    font-family: var(--metadata-font-fam);
    font-size: var(--metadata-lg-size);
    line-height: var(--metadata-line-height);
    font-weight: var(--metadata-font-weight);
  }
`

export default function LiveBanner({ placement }: LiveBannerProps) {
  return (
    <LiveBannerWrapper placement={placement}>
      <RedDot />
      <LiveIndicator />
    </LiveBannerWrapper>
  )
}
