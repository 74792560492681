import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { useEffect } from 'react'
import styled from 'styled-components'
import { NavigateForm } from './NavigateForm'
import { QuickActions } from './QuickActions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0;
  width: 100%;
  padding: 0;
`

export const InteractiveDebugMenu = () => {
  const { focusKey, setFocus, ref, getCurrentFocusKey } = useFocusable({
    isFocusBoundary: true,
    trackChildren: true,
  })

  useEffect(() => {
    // @ mount, get the previously focused key
    const currentFocusKey = getCurrentFocusKey()
    // @ mount, focus the debugUrl input
    setTimeout(() => {
      setFocus('DEBUG_RELOAD')
    }, 250)

    // @ unmount, refocus the previous focus key @ unmount
    return () => {
      setFocus(currentFocusKey)
    }
    /* eslint-disable-next-line */
  }, [])

  return (
    <FocusContext.Provider value={focusKey}>
      <Wrapper ref={ref}>
        <QuickActions />
        <NavigateForm />
      </Wrapper>
    </FocusContext.Provider>
  )
}
