import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'

export const exitApplicationIfApplicable = async () => {
  const deviceInfo = getWindowDeviceInfo()
  if (deviceInfo.platform === 'tv_samsung') {
    try {
      const ti = await import('tizen-common-web')
      ti.application.getCurrentApplication().exit()
    } catch (ex) {
      console.log(`Error exiting tizen => `, ex)
    }
  }
}
