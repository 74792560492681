import DebugMenu from '@/components/DebugMenu'
import { HardwallGuard } from '@/components/HardwallGuard/HardwallGuard'
import { TizenKeysProvider } from '@/contexts/TizenKeysContext'
import { UiContextProvider } from '@/contexts/UiContext'
import GlobalStyles from '@/styles/globalStyles'
import '@/styles/globals.css'
import { datadogLogs } from '@datadog/browser-logs'
import DDRum from '@grandstand-web/bally-web-shared/src/components/DDRum'
import { clientRuntimeConfig } from '@grandstand-web/bally-web-shared/src/config'
import { initializeStringMgmt } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import TosRedirect from '@grandstand-web/bally-web-shared/src/newPackages/TermsOfService/components/TosRedirect'
import { ServiceProvider } from '@grandstand-web/bally-web-shared/src/services/ServiceProvider'
import { GlobalStateContextWrapper } from '@grandstand-web/bally-web-shared/src/services/config/GlobalStateService'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { init as noriginSpatialNavigationInit, setKeyMap } from '@noriginmedia/norigin-spatial-navigation'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'

if (clientRuntimeConfig.dataDogClientToken) {
  try {
    datadogLogs.init({
      clientToken: clientRuntimeConfig.dataDogClientToken,
      site: 'datadoghq.com',
      service: 'connected-web',
      env: String(clientRuntimeConfig.environment),
      version: String(clientRuntimeConfig.rootVersion),
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    })
  } catch (err) {
    console.log(err)
  }
}

const XBoxControllerWrapper = dynamic(
  () => import('@/utils/xbox/xbox.gamepad-keyboard').then((lib) => lib.XBoxController),
  {
    ssr: false,
  }
)

noriginSpatialNavigationInit({
  shouldFocusDOMNode: true,
})

setKeyMap({
  left: [37, 205, 214],
  up: [38, 203, 211],
  right: [39, 206, 213],
  down: [40, 204, 212],
  enter: [13, 195],
  back: [27, 198],
})

export default function App({ Component, pageProps }: AppProps) {
  const deviceInfo = getWindowDeviceInfo()
  initializeStringMgmt('cw')

  return (
    <>
      <Head>
        <title>Bally Sports</title>
        <meta name="robots" content="noindex,nofollow" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" href="favicon.png" />
        <link rel="preconnect" href="https://ballysports.com" />
        <link rel="preconnect" href="https://middleware.stage.gs.ballysports.com" />
        <link rel="preconnect" href="https://configapi.ballysports.com" />
      </Head>
      <ServiceProvider gtmId={clientRuntimeConfig.gtmId} app="connected-web">
        <DDRum service="connected-web" />
        <TosRedirect />
        <GlobalStateContextWrapper>
          <TizenKeysProvider>
            <UiContextProvider>
              {deviceInfo?.platform === 'tv_xboxone' && <XBoxControllerWrapper />}
              <GlobalStyles />
              <Component {...pageProps} />
              <DebugMenu />
              <HardwallGuard />
            </UiContextProvider>
          </TizenKeysProvider>
        </GlobalStateContextWrapper>
      </ServiceProvider>
    </>
  )
}
