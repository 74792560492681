import { UpdateApp } from '@grandstand-web/bally-web-shared/src/services/config/Config'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { useLayoutEffect, useState } from 'react'
export interface ForceUpdate extends UpdateApp {}
export const useForceUpdate = () => {
  const deviceInfo = getWindowDeviceInfo()
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const [forceUpdate, setForceUpdate] = useState<ForceUpdate | null>(null)

  // check if the app needs to be updated and set the forceUpdate state
  useLayoutEffect(() => {
    const isXbox = deviceInfo.platform === 'tv_xboxone'
    const isTizen = deviceInfo.platform === 'tv_samsung'
    if (!isXbox && !isTizen) {
      return
    }
    const key = isXbox ? 'xbox' : 'tizen'
    const update = currentConfig.API.update?.[key]
    if (!update || !update.enabled) {
      setForceUpdate(null)
      return
    }

    const requiredVersion = update.required_version

    const getNextForceUpdate = (curVersion: string, reqVersion: string): ForceUpdate | null => {
      // compare major, minor, and patch versions
      const cur = curVersion.split('.').slice(0, 3).map(Number)
      const req = reqVersion.split('.').slice(0, 3).map(Number)
      for (let i = 0; i < cur.length; i++) {
        if (cur[i] < req[i]) {
          return update as ForceUpdate
        }
      }
      return null
    }
    setForceUpdate(getNextForceUpdate(deviceInfo.nativeVersion ?? '', requiredVersion))
  }, [currentConfig, deviceInfo.nativeVersion, deviceInfo.platform])
  return forceUpdate
}
