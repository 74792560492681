import { button, ParagraphLabel } from '@/styles/fonts'
import { useFocusable, UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation'
import { InputHTMLAttributes, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export type InputStyleProps = {
  focused: boolean
  error?: string
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  UseFocusableConfig<unknown> & {
    axeLabel?: string // role to be read aloud by screen reader
    axeRole?: string // text to be read aloud by screen reader
    focusKey: string
    error?: string
  }

export const inputStyles = css<InputStyleProps>`
  ${button}
  justify-content: flex-start;
  text-align: left;
  border: none;
  outline: none;
  background-color: transparent;
  border: 1px solid
    ${({ error, focused }) => (error ? 'var(--error, red)' : focused ? 'var(--button-focus)' : 'var(--button)')};
  color: var(--on-background);
  font-size: 32px !important;
  height: 88px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: background-color 150ms, color 150ms;
  white-space: nowrap;
  text-align: center;

  /* hide blinking cursor if not focused */
  caret-color: ${({ focused }) => (focused ? 'var(--button-focus)' : 'transparent')};

  /* hide arrows on number field */
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const StyledInput = styled.input<InputStyleProps>`
  ${inputStyles}
`
const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ErrorLabel = styled(ParagraphLabel)`
  color: var(--on-background);
`
export const Input = ({
  children,
  // focusable props
  focusable,
  saveLastFocusedChild,
  trackChildren,
  autoRestoreFocus,
  isFocusBoundary,
  focusKey,
  preferredChildFocusKey,
  onEnterPress,
  onEnterRelease,
  onArrowPress,
  onFocus,
  onBlur,
  // aria props
  axeLabel,
  axeRole,
  // misc
  error,
  // <input/> props
  // onChange,
  ...inputProps
}: PropsWithChildren<InputProps>) => {
  const focusStore = useFocusable({
    focusable,
    saveLastFocusedChild,
    trackChildren,
    autoRestoreFocus,
    isFocusBoundary,
    focusKey,
    preferredChildFocusKey,
    onEnterPress,
    onEnterRelease,
    onArrowPress,
    onFocus,
    onBlur,
  })
  const { ref, focused } = focusStore

  return (
    <InputContainer>
      <StyledInput
        {...inputProps}
        ref={ref}
        focused={focused}
        aria-label={axeLabel}
        role={axeRole}
        error={error}
        autoComplete="off"
      />
      {error && <ErrorLabel fontSize="3">{error}</ErrorLabel>}
    </InputContainer>
  )
}
