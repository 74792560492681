import { isSmartTV } from 'react-device-detect'
import styled from 'styled-components'

const PlayIconWrapper = styled.div`
  display: inline-flex;
  position: relative;
  overflow: visible;
  padding-right: 14px;
  height: 24px;
  width: 36px;
  justify-content: center;
  align-content: center;
`

const PlayIconStyle = styled.div<{ isSmartTV: boolean }>`
  position: absolute;
  top: ${(props) => (props.isSmartTV ? '0px' : '2px')};
  text-transform: capitalize;
  font-size: ${(props) => (props.isSmartTV ? '48px' : '52px')};
  font-weight: var(--button-font-weight);
  font-family: var(--button-font-fam);
`

const PlayIcon = () => {
  // samsung tizen doesn't display the ► charactor correctly, so we use the html code instead with
  // updated styles to display
  return (
    <PlayIconWrapper>
      <PlayIconStyle isSmartTV={isSmartTV}>&#8227;</PlayIconStyle>
    </PlayIconWrapper>
  )
}

export default PlayIcon
