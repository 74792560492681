import styled, { css } from 'styled-components'

export type HeadingFontSize = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
export type SubheadingFontSize = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
export type WinFontSize = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
export type ParagraphFontSize = '0' | '1' | '2' | '3' | '4'
export type LinkFontSize = '0' | '1' | '2' | '3' | '4' | '5'
export type CaptionFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg'
export type MetadataFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type PrimaryFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type SecondaryFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

/* Mixin Definitions */

export const heading = css<{ fontSize: HeadingFontSize }>`
  font-size: ${({ fontSize }) => `var(--heading-${fontSize}-size)`};
  line-height: var(--heading-line-height);
  font-weight: var(--heading-font-weight);
  font-family: var(--heading-font-fam);
`

export const subheading = css<{ fontSize: SubheadingFontSize }>`
  font-size: ${({ fontSize }) => `var(--sub-heading-${fontSize}-size)`};
  line-height: var(--sub-heading-line-height);
  font-weight: var(--sub-heading-font-weight);
  font-family: var(--sub-heading-font-fam);
`

export const win = css<{ fontSize: WinFontSize }>`
  font-size: ${({ fontSize }) => `var(--win-${fontSize}-size)`};
  line-height: var(--win-line-height);
  font-weight: var(--win-font-weight);
  font-family: var(--win-font-fam);
`

export const paragraph = css<{ fontSize: ParagraphFontSize }>`
  font-size: ${({ fontSize }) => `var(--paragraph-${fontSize}-size)`};
  line-height: var(--paragraph-line-height);
  font-weight: var(--paragraph-font-weight);
  font-family: var(--paragraph-font-fam);
`

export const link = css<{ fontSize: LinkFontSize }>`
  font-size: ${({ fontSize }) => `var(--link-${fontSize}-size)`};
  line-height: var(--link-line-height);
  font-weight: var(--link-font-weight);
  font-family: var(--link-font-fam);
`

export const caption = css<{ fontSize: CaptionFontSize }>`
  font-size: ${({ fontSize }) => `var(--caption-${fontSize}-size)`};
  line-height: var(--caption-line-height);
  font-weight: var(--caption-font-weight);
  font-family: var(--caption-font-fam);
`

export const metadata = css<{ fontSize: MetadataFontSize }>`
  font-size: ${({ fontSize }) => `var(--metadata-${fontSize}-size)`};
  line-height: var(--metadata-line-height);
  font-weight: var(--metadata-font-weight);
  font-family: var(--metadata-font-fam);
`

export const button = css`
  font-size: var(--button-xl-size);
  line-height: var(--button-line-height);
  font-weight: var(--button-font-weight);
  font-family: var(--button-font-fam);
`

export const labelPrimary = css<{ fontSize: PrimaryFontSize }>`
  font-size: ${({ fontSize }) => `var(--label-primary-${fontSize}-size)`};
  line-height: var(--label-primary-line-height);
  font-weight: var(--label-primary-font-weight);
  font-family: var(--label-primary-font-fam);
`

export const labelSecondary = css<{ fontSize: SecondaryFontSize }>`
  font-size: ${({ fontSize }) => `var(--label-secondary-${fontSize}-size)`};
  line-height: var(--label-secondary-line-height);
  font-weight: var(--label-secondary-font-weight);
  font-family: var(--label-secondary-font-fam);
`

/* Font Styled Components */

export const HeadingLabel = styled.div`
  ${heading}
`

export const SubheadingLabel = styled.div`
  ${subheading}
`

export const WinLabel = styled.div`
  ${win}
`

export const ParagraphLabel = styled.div`
  ${paragraph}
`

export const LinkLabel = styled.div`
  ${link}
`

export const CaptionLabel = styled.div`
  ${caption}
`

export const ButtonLabel = styled.div`
  ${button}
`

export const MetaDataLabel = styled.div`
  ${metadata}
`

export const PrimaryLabel = styled.div`
  ${labelPrimary}
`

export const SecondaryLabel = styled.div`
  ${labelSecondary}
`

export const SrOnlyText = styled.div`
  position: fixed;
  left: -100000px;
  top: -100000px;
  pointer-events: none;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
`
