import { UiContext } from '@/contexts/UiContext'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '../primitives/button'

const Container = styled.div`
  display: flex;
  width: 100%;
  & > * {
    padding: 16px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`
export const QuickActions = () => {
  const router = useRouter()
  const { setIsDebugMenuVisible } = useContext(UiContext)
  const { isLoggedIn, logOut } = useContext(UserServiceContext)
  const handleReloadPress = () => {
    window.location.reload()
  }
  const handleLogOutPress = () => {
    if (isLoggedIn) {
      logOut()
      router.replace('/')
    }
  }

  const handleClosePress = () => {
    setIsDebugMenuVisible(false)
  }

  return (
    <Container>
      <div>
        <PrimaryButton focusKey="DEBUG_RELOAD" onEnterPress={handleReloadPress}>
          Reload App
        </PrimaryButton>
      </div>
      {isLoggedIn && (
        <div>
          <PrimaryButton focusKey="DEBUG_LOGOUT" onEnterPress={handleLogOutPress}>
            Log Out
          </PrimaryButton>
        </div>
      )}
      <div>
        <PrimaryButton focusKey="DEBUG_CLOSE" onEnterPress={handleClosePress}>
          Close
        </PrimaryButton>
      </div>
    </Container>
  )
}
