import { UiContext } from '@/contexts/UiContext'
import { HeadingLabel, PrimaryLabel } from '@/styles/fonts'
import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import { isNotInProd } from '@grandstand-web/bally-web-shared/src/utils/envUtils'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '../primitives/button'
import { Input } from '../primitives/input'

const URLInput = styled(Input)`
  text-align: left;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0;
  padding-top: 32px;
`
const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`

const HistoryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  button {
    width: 100%;
  }
`

const isValidURL = (url: string) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export const NavigateForm = () => {
  const isProd = !isNotInProd()
  const { isDebugMenuVisible } = useContext(UiContext)
  const [debugUrl, setDebugUrl] = useState<string>('https://')
  const [history, setHistory] = useLocalStorage<string[]>('DEBUG_URL_HISTORY', [])
  const [error, setError] = useState<string | null>(null)
  const currentUrl = window.location.href
  useEffect(() => {
    if (!isValidURL(debugUrl)) {
      setError('Please enter a valid url')
    } else {
      setError(null)
    }
  }, [debugUrl])

  const handleSubmit = (url: string) => {
    if (isProd || !isDebugMenuVisible) {
      return
    }
    if (!isValidURL(url)) {
      setError('Please enter a valid url')
      return
    }
    // add the url to start of history (deduped)
    setHistory((prev) => [...new Set([url, ...prev])].slice(0, 3))

    window.location.href = url
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isProd || !isDebugMenuVisible) {
      return
    }
    setDebugUrl(e.target.value)
  }

  return (
    <Container>
      <HeadingLabel fontSize="6">Navigate to custom URL</HeadingLabel>
      <HistoryContainer>
        <HeadingLabel fontSize="4">Recent URLs</HeadingLabel>

        <HistoryGrid>
          {history.map((url) => (
            <PrimaryButton key={url} focusKey={url} onEnterPress={() => handleSubmit(url)}>
              {url}
            </PrimaryButton>
          ))}
          <PrimaryButton focusKey="CLEAR_HISTORY" onEnterPress={() => setHistory([])}>
            Clear
          </PrimaryButton>
        </HistoryGrid>
      </HistoryContainer>
      <Form>
        <HeadingLabel fontSize="4">New URL</HeadingLabel>
        <URLInput
          value={debugUrl}
          type="url"
          id="DEBUG_URL_INPUT"
          focusKey="DEBUG_URL_INPUT"
          placeholder={`Current URL: ${currentUrl}`}
          onChange={handleChange}
          onEnterPress={() => handleSubmit(debugUrl)}
        />
        {error && <PrimaryLabel fontSize="lg">{error}</PrimaryLabel>}
        <div>
          <PrimaryButton onEnterPress={() => handleSubmit(debugUrl)} focusKey="DEBUG_URL_SUBMIT">
            Go
          </PrimaryButton>
        </div>
      </Form>
    </Container>
  )
}
