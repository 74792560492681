import { UiContext } from '@/contexts/UiContext'
import { HeadingLabel } from '@/styles/fonts'
import { exitApplicationIfApplicable } from '@/utils/native.utils'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '../primitives/button'

const PanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
`

const Panel = styled.div`
  width: 100%;
  text-align: center;
  max-width: 1200px;
  width: 1200px;
  background-color: var(--surface-dark);
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 96px 0;
  margin: 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 96px;
  margin: 0;
  gap: 24px;
`

const Labels = styled(Section)``

const Title = styled(HeadingLabel)`
  color: var(--on-dark);
`

const Actions = styled(Section)`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`

const Action = styled(PrimaryButton)``

const focusKeys = {
  cancel: 'CANCEL_BUTTON',
  confirm: 'CONFIRM_BUTTON',
  all: ['CANCEL_BUTTON', 'CONFIRM_BUTTON'],
}
const prevKeys = {
  home: 'WATCH_BUTTON',
  hardwall: 'GET_BALLY_SPORTS',
}

const getIsExitKey = (focusKey: string) => focusKeys.all.includes(focusKey)

function InnerExitPanel() {
  const router = useRouter()
  const { exitPanelVisible, setExitPanelVisible } = useContext(UiContext)
  const { setFocus, focusKey, ref, getCurrentFocusKey } = useFocusable({
    isFocusBoundary: true,
  })

  const visibleRef = useRef<boolean>(false)
  const prevKeyRef = useRef<string>(getCurrentFocusKey())

  // keep refs up to date
  useEffect(() => {
    // update refs
    visibleRef.current = exitPanelVisible
    const currentKey = getCurrentFocusKey()
    const isExitKey = getIsExitKey(currentKey)
    const isHomeRoute = router.asPath.indexOf('/home') > -1
    prevKeyRef.current = !isExitKey ? currentKey : isHomeRoute ? prevKeys.home : prevKeys.hardwall
    if (!isExitKey && exitPanelVisible) {
      setFocus(focusKeys.cancel)
    }
    if (isExitKey && !exitPanelVisible) {
      setExitPanelVisible(true)
      setFocus(focusKeys.cancel)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitPanelVisible, focusKey, router.asPath])

  useEffect(() => {
    return () => {
      // reset focus to prevKeyRef.current
      setFocus(prevKeyRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // create an onClick handler that will re-route to couchrights, video, OR video with fromBeginning=true
  const handleCancel = () => {
    setExitPanelVisible(false)
  }

  const handleConfirm = () => {
    exitApplicationIfApplicable()
  }

  return (
    <PanelWrapper>
      <Panel>
        <Sections>
          <Labels>
            <Title fontSize="6">Do you want to exit Bally Sports+?</Title>
          </Labels>
          <FocusContext.Provider value={focusKey}>
            <Actions ref={ref}>
              <Action
                focusKey={focusKeys.cancel}
                onEnterPress={handleCancel}
                axeLabel="Do you want to exit Bally Sports+? Cancel"
              >
                Cancel
              </Action>
              <Action focusKey={focusKeys.confirm} onEnterPress={handleConfirm} axeLabel="Exit">
                Exit
              </Action>
            </Actions>
          </FocusContext.Provider>
        </Sections>
      </Panel>
    </PanelWrapper>
  )
}

export default function ExitPanel() {
  const { exitPanelVisible } = useContext(UiContext)

  if (!exitPanelVisible) {
    return null
  }

  return <InnerExitPanel />
}
