import { TizenKeysContext } from '@/contexts/TizenKeysContext'
import { xboxKeyForEvent } from '@/utils/xbox/xboxKeyForEvent'
import { useContext, useEffect, useRef } from 'react'
type KeyOrKeys = string | string[]

type KeyEventCallback = (event: KeyboardEvent, matched: string[]) => void
const useKeyEvent = (
  keyOrKeys: KeyOrKeys,
  callback: KeyEventCallback,
  optionsForListener?: AddEventListenerOptions
) => {
  const { keyForEvent } = useContext(TizenKeysContext)

  const ref = useRef({
    keyOrKeys,
    callback,
    optionsForListener,
  })

  ref.current = { keyOrKeys, callback, optionsForListener }

  useEffect(() => {
    const { keyOrKeys, callback, optionsForListener } = ref.current
    const keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys]
    const handleKeyboardEvent = (event: KeyboardEvent) => {
      const xboxKey = xboxKeyForEvent(event)
      const tizenKey = keyForEvent(event)
      const eventStrs: (string | undefined)[] = [event.key, event.code, xboxKey.key, tizenKey.name]
      const matched = keys.filter((key) => {
        return eventStrs.some((str) => {
          return str === key || str?.toLowerCase() === key?.toLowerCase()
        })
      })

      if (matched.length > 0) {
        callback(event, matched)
      }
    }
    document.addEventListener('keyup', handleKeyboardEvent, optionsForListener)
    return () => {
      document.removeEventListener('keyup', handleKeyboardEvent)
    }
  }, [keyForEvent])
}
export default useKeyEvent
