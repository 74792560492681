import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'

type XboxKey = {
  key: string
  which: number
}

const xboxKeys: XboxKey[] = [
  { key: 'GamepadLeftThumbstickLeft', which: 37 },
  { key: 'GamepadLeftThumbstickUp', which: 38 },
  { key: 'GamepadLeftThumbstickRight', which: 39 },
  { key: 'GamepadLeftThumbstickDown', which: 40 },
  { key: 'GamepadDPadLeft', which: 205 },
  { key: 'GamepadDPadUp', which: 203 },
  { key: 'GamepadDPadRight', which: 206 },
  { key: 'GamepadDPadDown', which: 204 },
  { key: 'GamepadRightThumbstickLeft', which: 218 },
  { key: 'GamepadRightThumbstickUp', which: 215 },
  { key: 'GamepadRightThumbstickRight', which: 217 },
  { key: 'GamepadRightThumbstickDown', which: 216 },
  { key: 'GamepadA', which: 195 },
  { key: 'GamepadB', which: 27 },
  { key: 'GamepadX', which: 197 },
  { key: 'GamepadY', which: 198 },
  { key: 'GamepadRightShoulder', which: 199 },
  { key: 'GamepadLeftShoulder', which: 200 },
  { key: 'GamepadLeftTrigger', which: 201 },
  { key: 'GamepadRightTrigger', which: 202 },
]
export function xboxKeyForEvent(event: KeyboardEvent): XboxKey {
  const deviceInfo = getWindowDeviceInfo()
  const base: XboxKey = { key: 'Unidentified', which: -1 }
  if (deviceInfo.platform !== 'tv_xboxone' || event.key !== 'Unidentified') {
    // the key is already valid and shouldn't be matched with xboxKeys
    return base
  }
  const match = xboxKeys.find((key) => key.which === event?.keyCode || key.which === event?.which)
  return match ?? base
}
