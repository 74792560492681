import { UiContext } from '@/contexts/UiContext'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

export const HardwallGuard = () => {
  const userService = useContext(UserServiceContext)
  const { forceUpdate } = useContext(UiContext)
  const router = useRouter()

  useEffect(() => {
    const pathname = window.location.pathname
    const currentRoutes = [router?.pathname ?? pathname, pathname]
    const matchRoutes = (routes: string[]) =>
      routes.some((route) => currentRoutes.some((currentRoute) => currentRoute === route))

    if (forceUpdate) {
      if (matchRoutes(['/force-update'])) {
        return
      }
      router.replace(`/force-update`)
      return
    }

    if (
      !userService.isLoggedIn &&
      !matchRoutes(['/hardwall', '/onboarding/signin', '/upsell/purchase', '/force-update'])
    ) {
      router.replace('/hardwall')
      return
    }
  }, [forceUpdate, router, userService.isLoggedIn])

  return <></>
}
